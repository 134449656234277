angular.module("aerosApp")
    .factory('unmarkDeleteFiberGroupModal', unmarkDeleteFiberGroupModal);

unmarkDeleteFiberGroupModal.$inject = ['$uibModal'];

function unmarkDeleteFiberGroupModal($uibModal) {
    function open(fiberGroup, projectId, orgId) {
        var modal = $uibModal.open({
            size: "md",
            resolve: {
                fiberGroup: function () {
                    return fiberGroup;
                },
                projectId: function () {
                    return projectId;
                },
                orgId: function () {
                    return orgId;
                }
            },
            templateUrl: "/static/templates/project/unmarkDeleteFiberGroupModal.html",
            controller: unmarkDeleteFiberGroupModalController
        });
        return modal.result;
    }

    return {
        open: open
    };
}

unmarkDeleteFiberGroupModalController.$inject = ['$scope', '$rootScope', '$uibModalInstance', 'aerosApi', 'fiberGroup',
    'projectId', 'orgId'];

function unmarkDeleteFiberGroupModalController($scope, $rootScope, $uibModalInstance, aerosApi, fiberGroup, projectId,
                                               orgId) {
    angular.extend($scope, {
        fiberGroup: fiberGroup,
        moment: moment,
        unmark: unmark,
        deleteFG: deleteFG
    });

    function unmark() {
        aerosApi.unmarkFiberGroupFromDelete(orgId, projectId, fiberGroup.uuid)
            .then(function (result) {
                $uibModalInstance.close({action: "unmark", result: result});
            });
    }

    function deleteFG() {
        aerosApi.deleteFiberGroups([fiberGroup.id], orgId)
            .then(function (result) {
                $uibModalInstance.close({action: "delete", result: result});
            });
    }

}